body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eeeff0;
}

input {
  outline: none;
}

#browse-files {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#browse-files-label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: rgba(183, 81, 224, 0.95);
  color: #ffffff;
  border: 2px solid rgba(183, 81, 224, 0.95);
  border-radius: 8px;
  width: 350px;
  margin: auto;
}

#browse-files-label:hover {
  opacity: 0.5;
}

input[type="range"] {
  accent-color: rgba(183, 81, 224, 0.95);
}

select {
  background: url("data:image/svg+xml,<svg height='15px' width='15px' viewBox='0 0 16 16' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.chatroompasscode::placeholder {
  color: #eeeeee;
  opacity: 1; /* Firefox */
}

.chatroompasscode::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #eeeeee;
}

.markdown {
  font-weight: 400;
  font-size: 14px;
}
.markdown h1 {
  font-size: 1.5rem;
  font-weight: 400;
}
.markdown h2 {
  font-size: 1.3rem;
  font-weight: 400;
}
.markdown h3 {
  font-size: 1.1rem;
  font-weight: 400;
}
