.participants {
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-gap: 15px;
  margin-top: 0;
  padding: 10px;
}

.participants img {
  width: 33%;
  aspect-ratio: 1;
  border-radius: 50%;
}

@media (max-width: 650px) {
  .participants {
    grid-template-columns: repeat(var(--grid-col-size), 1fr);
    grid-template-rows: repeat(var(--grid-row-size), 1fr);
  }
}
