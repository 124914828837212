.meeting-footer {
  position: fixed;
  background: #3c4043;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20;
}

.meeting-icons {
  color: white;
  border-radius: 50%;
  background: #3c4043;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active {
  background-color: rgba(183, 81, 224, 0.95);
}

.exit-meeting-icon {
  color: white;
  border-radius: 50%;
  background: #eb5757;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.share-meeting-icon {
  color: white;
  border-radius: 50%;
  background: #cccccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.3;
}
