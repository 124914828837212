.video {
  background-color: #000000;
  aspect-ratio: 16/9;
  margin: auto;
  position: relative;
  object-fit: contain;
  border-radius: 8px;
}

.participant {
  background-color: #000000;
  width: 100%;
  position: relative;
  border-radius: 8px;
  min-height: 200px;
}

.muted {
  color: #fff;
  right: 10px;
  position: absolute;
  top: 10px;
  background: #3c4043;
  border-radius: 50%;
  height: 22px;
  width: 22px !important;
}

.hide {
  display: none;
}

.avatar {
  width: 33%;
  aspect-ratio: 1;
  border-radius: 50%;
  font-size: 40px;
  font-family: roboto, arial;
  color: white;
  text-align: center;
  background: rgba(183, 81, 224, 0.95);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.name {
  color: white;
  bottom: 10px;
  position: absolute;
  left: 20px;
  font-size: 12;
}

@media (max-width: 650px) {
  .participant {
    min-height: 0px;
  }
  .avatar {
    width: 33%;
    aspect-ratio: 1;
    border-radius: 50%;
  }
}
